import { createAction } from '../common/actionUtils';
import {
  CloseModalPayload,
  NavigatePayload,
  OpenModalPayload,
  SetPaymentMethodsPayload,
  SetOrganizationFullPayload,
  SetIsUserLoggedInPayload,
  SetSavedAddressesPayload,
  SetIsLoadingAddressesFromServerPayload,
  SaveAddressToServerPayload,
} from './session.actions.types';
import { SetSignedInstancePayload } from '../platformParams/platformParams.actions.types';

export const setOrganizationFull = createAction<SetOrganizationFullPayload>('SET_ORGANIZATION_FULL');
export const openModal = createAction<OpenModalPayload>('OPEN_MODAL');
export const closeModal = createAction<CloseModalPayload>('CLOSE_MODAL');
export const navigate = createAction<NavigatePayload>('NAVIGATE');
export const saveStateToSessionStorage = createAction('SAVE_ORDER_ITEMS_TO_SESSION_STORAGE');
export const clearSessionStorage = createAction('CLEAR_SESSION_STORAGE');
export const initApp = createAction('INIT_APP');
export const setPaymentMethods = createAction<SetPaymentMethodsPayload>('SET_PAYMENT_METHODS');
export const setIsUserLoggedIn = createAction<SetIsUserLoggedInPayload>('SET_IS_USER_LOGGED_IN');
export const setSavedAddresses = createAction<SetSavedAddressesPayload>('SET_SAVED_ADDRESSES');
export const userLoggedIn = createAction('USER_LOGGED_IN');
export const setIsLoadingAddressesFromServer = createAction<SetIsLoadingAddressesFromServerPayload>(
  'SET_IS_LOADING_ADDRESSES_FROM_SERVER',
);
export const saveAddressToServer = createAction<SaveAddressToServerPayload>('SAVE_ADDRESS_TO_SERVER');
export const setSignedInstance = createAction<SetSignedInstancePayload>('SET_SIGNED_INSTANCE');
